<div class="container"
    fxLayout
    fxLayout.xs="column"
    fxLayoutAlign="center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="0">
    <div class="item item-1" fxFlex="25%"></div>
    <div class="item item-2" fxFlex>
        <div class="main hero">
            <div>
                <h1 style="margin-top: 100px; font-size: 64px;">404 Not Found</h1>
                <img style="display: block;" src="assets/images/notfound.png">
            </div>
        </div>
    </div>
    <div class="item item-1" fxFlex="25%"></div>
</div>