<div fxFlex="15%" fxFlex.md="5%" fxFlex.sm="2%" fxFlex.xs="2%"></div><div fxFlex fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap="15px" class="main">
    
    <mat-card class="card" routerLink="/trove">
        <img mat-card-image src="assets/images/hero1.png" alt="Treasure Trove">
        <mat-card-content class="cardtext">
            Treasure Trove
        </mat-card-content>
    </mat-card>

    <mat-card class="card" routerLink="/encounter">
        <img mat-card-image src="assets/images/hero2.png" alt="Encounter Generator">
        <mat-card-content class="cardtext">
            Encounter Generator
        </mat-card-content>
    </mat-card>

    <mat-card class="card" routerLink="/maker">
        <img mat-card-image src="assets/images/notfound.png" alt="Make a Monster!">
        <mat-card-content class="cardtext">
            Under Construction
        </mat-card-content>
    </mat-card>

</div><div fxFlex="15%" fxFlex.md="5%" fxFlex.sm="2%" fxFlex.xs="2%"></div>